const ruLang = {
  class_schedule_home: "Расписание уроков",
  list_of_classes_home: "Список классов",
  teacher_home: "Учителя",
  student_home: "Ученики",
  sciences: "Предметы",
  personal_information_home: "Личная информация",
  add_student: "Добавить ученика",
  add_teacher: "Добавить учителя",
  number_of_students: "ученик",
  edit: "Редактировать",
  delete: "Удалить",
  save: "Сохранить",
  class_creation: "Создать класс",
  login_parol: "Логин",
  search_in_name: "Поиск по имени и фамилии",
  back_out: "Назад",
  table_pupils: "Ученики",
  table_classes: "Класс",
  table_teacher: "Учителя",
  table_subject: "Предмет",
  table_birthday: "День рождения",
  table_number: "Номер телефона",
  table_more: "Подробнее",
  placeholder_search: "Поиск по ФИО",
  active_table: "Активный",
  add_student_in_file: "Загрузить файл",
  male: "Мужчина",
  female: "Женщина",
  to_teach: "Какой предмет преподает *",
  all_science: "Все",
  select_science: "Выберите предмет",
  select_teacher: "Выберите учителя",
  hidden_teacher: "Учитель не найден",
  term: "Четверть",
  All_student_select: "Все ученики",
  name_subjects: "Название предметов",
  creating_sciences: "Добавить предмет",
  exit: "Выйти",
  no_teacher_subject: "Предметов не найдено",
  settings: "Настройки",
  level: "Должность",
  teacher_level: "Учитель",
  director: "Директор",
  deputy_director: "Заместитель директора",
  admin: "Администратор",
  enter: "Введите",
  editing: "Архивировать",
  personal_information: "Личная информация",
  firstName: "Имя",
  lastName: "Фамилия",
  patronymic: "Отчество",
  birthday: "День рождения",
  gender: "Пол",
  state: "Государство",
  nation: "Национальность",
  province: "Область",
  district: "Район",
  home_address: "Домашний адрес",
  phone_number: "Номер телефона",
  additional_phone_number: "Дополнительный номер",
  science_teacher: "Учитель предмета",
  login: "Логин",
  password: "Пароль",
  prompt_value: "Если хотите удалить все данные о классе, напишите 'YES'. Внимание, все данные о классе и учениках будут удалены!",
  delete_value: "Данные не удалены, попробуйте снова",
  no_date: "Данные не найдены или обновите страницу...",
  save_image: "Сейчас невозможно изменить профильное изображение",
  time_schedule: "Время уроков",
  position: "Должности",
  archive_admin: "Архив администраторов",
  archive_home: "Архив учителей",
  archive_student: "Архив учеников",
  hour: "час",
  add_lesson_time: "Добавить время урока",
  select_hour: "Выберите время",
  edit_lesson_time: "Редактировать время урока",
  select_times: "Выберите время урока",
  "inactive_table": "Архивированный",
  create_admin: "Создать администратора",
  information_create_admin: "Создание администратора невозможно! Пожалуйста, обратитесь по номеру +998978611199.",
  "info_warning": "Активен",
  "info_danger": "Недоступен"
};

export default ruLang;