const enLang = {
  class_schedule_home: "Class schedule",
  list_of_classes_home: "Class list",
  teacher_home: "Teachers",
  student_home: "Students",
  sciences: "Subjects",
  personal_information_home: "Personal information",
  add_student: "Add student",
  add_teacher: "Add teacher",
  number_of_students: "students",
  edit: "Edit",
  delete: "Delete",
  save: "Save",
  class_creation: "Create class",
  login_parol: "Login",
  search_in_name: "Search by name and surname",
  back_out: "Back",
  table_pupils: "Students",
  table_classes: "Class",
  table_teacher: "Teachers",
  table_subject: "Subject",
  table_birthday: "Birthday",
  table_number: "Phone number",
  table_more: "More",
  placeholder_search: "Search by name",
  active_table: "Active",
  add_student_in_file: "Upload file",
  male: "Male",
  female: "Female",
  to_teach: "Which subject does the teacher teach *",
  all_science: "All",
  select_science: "Select subject",
  select_teacher: "Select teacher",
  hidden_teacher: "Teacher not found",
  term: "Term",
  All_student_select: "All students",
  name_subjects: "Subject names",
  creating_sciences: "Add subject",
  exit: "Logout",
  no_teacher_subject: "No subjects found",
  settings: "Settings",
  level: "Position",
  teacher_level: "Teacher",
  director: "Director",
  deputy_director: "Deputy Director",
  admin: "Administrator",
  enter: "Enter",
  editing: "Archive",
  personal_information: "Personal information",
  firstName: "First name",
  lastName: "Last name",
  patronymic: "Middle name",
  birthday: "Birthday",
  gender: "Gender",
  state: "State",
  nation: "Nationality",
  province: "Province",
  district: "District",
  home_address: "Home address",
  phone_number: "Phone number",
  additional_phone_number: "Additional number",
  science_teacher: "Subject teacher",
  login: "Login",
  password: "Password",
  prompt_value: "If you want to delete all data related to the class, type 'YES'.\n Note that all class data and student information will be deleted!",
  delete_value: "Data was not deleted, please try again",
  no_date: "No data found, or refresh the page...",
  save_image: "Profile picture cannot be changed at this time",
  time_schedule: "Lesson times",
  position: "Positions",
  archive_admin: "Admin archives",
  archive_home: "Teacher archives",
  archive_student: "Student archives",
  hour: "hour",
  add_lesson_time: "Add lesson time",
  select_hour: "Select time",
  edit_lesson_time: "Edit lesson time",
  select_times: "Select lesson time",
  inactive_table: "Archived",
  create_admin: "Create admin",
  information_create_admin: "Creating an administrator is not possible! Please contact +998978611199.",
  "info_warning": "Active",
  "info_danger": "Unavailable"


};
export default enLang;