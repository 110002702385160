const uzLang = {
  class_schedule_home: "Dars jadvali",
  list_of_classes_home: "Sinflar ro‘yxati",
  teacher_home: "O‘qituvchilar",
  student_home: "O'quvchilar",
  sciences: "Fanlar",
  personal_information_home: "Shaxsiy ma'lumotlar",
  add_student: "O'quvchi qo'shish",
  add_teacher: "O'qitucvhi qo'shish",
  number_of_students: "ta O'quvchi",
  edit: "Tahrirlash",
  delete: "O'chirish",
  save: "Saqlash",
  class_creation: "Sinf yaratish",
  login_parol: "Login",
  search_in_name: "Ism va Familiya bo'yicha izlang",
  back_out: "Orqaga chiqish",
  // *******************************************************************
  table_pupils: "O‘quvchilar",
  table_classes: "Sinf",
  table_teacher: "O‘qituvchilar",
  table_subject: "Fan",
  table_birthday: "Tug’ilgan kun",
  table_number: "Telefon raqam",
  table_more: "Batafsil",
  placeholder_search: "FISH bo'yicha qidiruv",
  active_table: "Faollik",
  add_student_in_file: "Fayl yuklang",
  male: "Erkak",
  female: "Ayol",
  to_teach: "Qaysi fandan dars beradi *",
  all_science: "Hammasi",
  select_science: "Fanni tanlang",
  select_teacher: "O'qituvchini tanlang",
  hidden_teacher: "O'qituvchi mavjud emas",
  term: "Chorak",
  All_student_select: "Hamma o'quvchilar",
  name_subjects: "Fanlar nomi",
  creating_sciences: "Fan qo'shish",
  exit: "Tizimdan chiqish",
  no_teacher_subject: "Fanlar mavjud emas",
  settings: "Sozlamalar",

  level: "Lavozimi",
  teacher_level: "O'qituvchi",
  director: "Director",
  deputy_director: "Direktor o'rinbosari",
  admin: "Administrator",
  //
  enter: "Kiriting",
  editing: "Arxivlash",
  personal_information: "Shaxsiy ma’lumotlar",
  firstName: "Ismi",
  lastName: "Familiya",
  patronymic: "Otasining ismi",
  birthday: "Tug‘ilgan kun",
  gender: "Jins",
  state: "Davlat",
  nation: "Millat",
  province: "Viloyat",
  district: "Tuman",
  home_address: "Uy manzili",
  phone_number: "Telefon raqam",
  additional_phone_number: "Qo'shimcha",
  science_teacher: "Fan o'qituvchisi",
  login: "Login",
  password: "Parol",
  prompt_value: "Sinfga tegishli barcha ma'lumotlar o'chishini istasangiz 'YES' deb yozing,\n E'tibor bering sinfning barcha ma'lumotlar va o'quvchilari malumotlari o'chirib yuboriladi!",
  delete_value: "Maʼlumotlar o'chirilmadi qayta urinib ko'ring",
  no_date: "Hech qanday maʼlumot topilmadi, yoki sahifani yangilang...",
  save_image: "Hozircha profil suratini o'zgartirib bo'lmaydi",
  time_schedule: "Dars vaqtlari",
  position: "Lavozimlar",
  archive_admin: "Arxiv adminlar",
  archive_home: "Arxiv o'qituvchilar",
  archive_student: "Arxiv o'quvchilar",
  hour: "soat",
  add_lesson_time: "Dars vaqtini qo'shing",
  select_hour: "Vaqtni tanlang",
  edit_lesson_time: "Dars vaqtini tahrirlash",
  select_times: "Dars vaqtini tanlang",
  inactive_table: "Arxivlangan",
  create_admin: "Admin yaratish",
  information_create_admin: "Administrator yaratish imkoniyati mavjud emas!  Iltimos +998978611199 raqamiga murojaat qiling.",
  info_warning: "Aktiv",
  info_danger: "Mavjud emas",

};

export default uzLang;